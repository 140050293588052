import $ from "jquery";

import "../styles/main.scss";
import "./nav-mobile";

import { registerPlayer } from "./plyr";

const ready = function() {
  registerPlayer("#initializePlyr", ".plyr-player");

  const $welcomeForm = $(".welcome-form form");
  // const $fieldsets = $welcomeForm.find("fieldset");

  $welcomeForm.on("submit", async event => {
    event.preventDefault();

    const contacts = event.target.contacts.value;

    if (!contacts) {
      $welcomeForm.addClass("--client-error");
      return;
    }

    $welcomeForm
      .addClass("--sending")
      .removeClass(["--server-error", "--sent"]);
    // $fieldsets.attr("disabled", "true");

    const budget = event.target.budget.value;
    const company = event.target.company.value;
    const name = event.target.name.value;
    const description = event.target.description.value;

    const options = [];

    $welcomeForm.find('[name="options"]:checked').each(function() {
      options.push(this.value);
    });

    const { action, method } = event.target;
    const response = await fetch(action, {
      method,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        budget,
        company,
        name,
        contacts,
        description,
        options
      })
    });

    $welcomeForm.removeClass("--sending");
    // $fieldsets.removeAttr("disabled");

    if (response.ok) $welcomeForm.addClass("--sent");
    else $welcomeForm.addClass("--server-error");
  });

  $welcomeForm.find('[name="contacts"]').on("focus", () => {
    $welcomeForm.toggleClass("--client-error", false);
  });
};

$(document).ready(ready);
